


/* all product css  */
.container {
  height: 350vh;
}

.sticky{
  position: sticky;
  overflow: hidden;
  top: 0;
  height: 100vh;
  width: 99vw;
}

.el {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 8px; */
}

.productimage {
  height: 100%;
  width: 100%;
  /* border-radius: 8px; */
  object-fit: cover;
}

.el .imageContainer {
  position: relative;
  bottom: 10vh;
  width: 25vw;
  height: 38vh;
}

.el:nth-of-type(2) .imageContainer {
  top: 30vh;
  left: -27vw;
  width: 25vw;
  height: 38vh;
}

.el:nth-of-type(3) .imageContainer {
  top: -10vh;
  left: -27vw;
  width: 25vw;
  height: 38vh;
}

.el:nth-of-type(4) .imageContainer {
  top: -10vh;
  left: 27.5vw;
  width: 25vw;
  height: 38vh;
}

.el:nth-of-type(5) .imageContainer {
  top: 30vh;
  left: 0vw;
  width: 25vw;
  height: 38vh;
}

.el .imageContainer img {
  object-fit: cover;
}

.imagegradient {
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  /* border-radius: 8px; */
}

.el .contentbox {
  position: absolute;
  top: 75%;
  bottom: 10%;
  left: 5%;
  z-index: 1;
}

.el .headingfontimg {
  color: #FFFFFF;
  font-size: 1.4vw !important;
  font-weight: 500;
}

.imgcontent {
  color: #FFFFFF;
  font-size: 1vw !important;
  width: fit-content;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #FFFFFF;
}

@media only screen and (max-width: 1440px) {
  .imgsection {
      width: 70%;
  }
  .headingfontimg {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .imgsection {
      width: 75%;
  }
}

@media only screen and (max-width: 1000px) {
  
  .imgsection {
      width: 85%;
  }
}

@media only screen and (max-width: 900px) {
  
  .imgsection {
      width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .el .imageContainer {
    position: relative;
    bottom: 10vh;
    width: 25vw;
    height: 30vh;
  }
  
  .el:nth-of-type(2) .imageContainer {
    top: 23vh;
    left: -27vw;
    width: 25vw;
    height: 30vh;
  }
  
  .el:nth-of-type(3) .imageContainer {
    top: -10vh;
    left: -27vw;
    width: 25vw;
    height: 30vh;
  }
  
  .el:nth-of-type(4) .imageContainer {
    top: -10vh;
    left: 27.5vw;
    width: 25vw;
    height: 30vh;
  }
  
  .el:nth-of-type(5) .imageContainer {
    top: 23vh;
    left: 0vw;
    width: 25vw;
    height: 30vh;
  }
  .headingfontimg{
      font-size: 15px !important;
  }
}
@media only screen and (max-width: 600px) {
  .el .imageContainer {
    position: relative;
    bottom: 10vh;
    width: 25vw;
    height: 20vh;
  }
  
  .el:nth-of-type(2) .imageContainer {
    top: 13vh;
    left: -27vw;
    width: 25vw;
    height: 20vh;
  }
  
  .el:nth-of-type(3) .imageContainer {
    top: -10vh;
    left: -27vw;
    width: 25vw;
    height: 20vh;
  }
  
  .el:nth-of-type(4) .imageContainer {
    top: -10vh;
    left: 27.5vw;
    width: 25vw;
    height: 20vh;
  }
  
  .el:nth-of-type(5) .imageContainer {
    top: 13vh;
    left: 0vw;
    width: 25vw;
    height: 20vh;
  }
  .headingfontimg{
      font-size: 12px !important;
  }
  .imgcontent{
      font-size: 12px;
  }
}
@media only screen and (max-width: 425px) {
  .headingfontimg{
      font-size: 12px !important;
  }
  .imgcontent{
      font-size: 8px;
  }
}