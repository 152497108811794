.expressmain{
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: -20;
    /* margin-bottom: 23vh; */
}

.express{
    height: 100%;
    width: 100%;
}

.imgbg{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.expressbg {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.content{
    position: relative;
    bottom: 30vh;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}
.express1{
    position: absolute;
    bottom: 55%;
    width: 80vw;
    height: 80vh;
    left: 18%;
}

.express2{
    position: absolute;
    bottom: 55%;
    width: 80vw;
    height: 80vh;
    left: 18%;

}

.express3{
    position: absolute;
    bottom: 55%;
    width: 80vw;
    height: 80vh;
    left: 18%;

}
.express4{
    position: absolute;
    bottom: 55%;
    width: 80vw;
    height: 80vh;
    left: 18%;

}
.express5{
    position: absolute;
    bottom: 55%;
    width: 80vw;
    height: 80vh;
    left: 18%;

}
.express6{
    position: absolute;
    bottom: 55%;
    width: 80vw;
    height: 80vh;
    left: 18%;

}
.express7{
    position: absolute;
    bottom: 55%;
    width: 80vw;
    height: 80vh;
    left: 18%;
}

@media only screen and (min-width: 1300px) {
    
}

@media only screen and (max-width: 768px) {
    .expressmain{
        /* margin-bottom: 100px; */
    }
    .express1{
        position: absolute;
        bottom: 60%;
        width: 80vw;
        height: 80vh;
        left: 17%;
    }
    
    .express2{
        position: absolute;
        bottom: 60%;
        width: 80vw;
        height: 80vh;
        left: 17%;
    }
    
    .express3{
        position: absolute;
        bottom: 60%;
        width: 80vw;
        height: 80vh;
        left: 17%;
    }
    .express4{
        position: absolute;
        bottom: 60%;
        width: 80vw;
        height: 80vh;
        left: 17%;
    }
    .express5{
        position: absolute;
        bottom: 60%;
        width: 80vw;
        height: 80vh;
        left: 17%;
    }
    .express6{
        position: absolute;
        bottom: 60%;
        width: 80vw;
        height: 80vh;
        left: 17%;
    }
    .express7{
        position: absolute;
        bottom: 60%;
        width: 80vw;
        height: 80vh;
        left: 17%;
    }
}

@media only screen and (max-width: 510px) {
    
}