.flybanner{
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: -20;
    /* top: 30px; */
    /* margin-bottom: 10vh; */
}

.flymain{
    height: 100%;
    width: 100%;
}

.imgSection{
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    height: 100%;
}

.flyimg{
    height: calc(100vh - 100px);
    width: 100%;
    object-fit: cover;
}

.flycontent{
    position: relative;
    bottom: 82vh;
    right: 5vw;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    text-transform: uppercase;
    color: white;
}


@media only screen and (min-width: 1300px) {
    .flybanner{
        /* margin-bottom: 60px; */
    }
}

@media only screen and (max-width: 768px) {
    .flybanner{
        /* margin-bottom: 70px; */
    }
    .flycontent{
        width: 70vw;
        right: -20vw;
    }
}

@media only screen and (max-width: 510px) {
    .flybanner{
        /* margin-bottom: 80px; */
    }
}