.container {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/Clip\ path\ group.png');
  background-size: cover;
  background-position: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 50%;
}

.registerbtn {
  border: none;
  color: #265EE1 !important;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  border-radius: 5px;
  background-color: #FAFAFA;
  padding: 10px 20px;
}

.contactbtn {
  border: 2px solid #FAFAFA;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px 20px;
  color: #FAFAFA;
  font-size: 14px;
  font-weight: 600;
}

.headingfont {
  color: #08132D;
  font-size: 34px;
  font-weight: 700;
}

.contentfont {
  color: #08132D;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Open Sans';
}

@media only screen and (max-width: 1350px) {
  .contentfont {
    font-size: 18px;
  }

  .content {
    width: 100%;
  }

  .headingfont {
    font-size: 30px;
  }
}

@media only screen and (max-width:768px) {
  .container{
    position: relative;
    top: -20%;
    height: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .container{
    position: relative;
    top: -20%;
    height: 60%;
  }
  .contentfont {
    font-size: 16px;
  }

  .headingfont {
    font-size: 25px;
  }
}

@media only screen and (max-width: 510px) {
  .container{
    position: relative;
    top: -40%;
    height: 70%;
  }
  .contentfont {
    font-size: 14px;
  }

  .headingfont {
    font-size: 18px;
  }
}

@media only screen and (max-width: 425px) {
  .registerbtn,
  .contactbtn {
    font-size: 14px;
    padding: 8px 18px;
  }
}