.maindiv {
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #51A2FC 0%, #0074F3 100%) !important;
}

.mapContainer{
    position: relative;
    top: -7%;
}

.mapContainer g path {
    stroke: #50A2FC;
    stroke-width: 0.5px;
}

.mapContainer svg g path:focus {
    outline: transparent;
}

.map {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.mapwidth {
    height: 100%;
}

.map::-webkit-scrollbar {
    display: none;
}

.map {
    height: 100%;
    width: 100%;
}

.mapContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.ourlocation {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.headingfont {
    color: #FAFAFA;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
}

.content {
    color: #FAFAFA;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .headingfont {
        font-size: 25px;
    }

    .content {
        font-size: 16px;
    }
}

@media only screen and (max-width: 450px) {
    .headingfont {
        font-size: 20px;
    }

    .content {
        font-size: 14px;
    }

    .mapContainer {
        width: 700px;
    }

    .map {
        overflow: auto;
    }
}

@media only screen and (max-width: 375px) {
    .mapContainer {
        width: 500px;
    }
}

@media only screen and (max-width: 320px) {
    .mapContainer {
        width: 400px;
    }
}