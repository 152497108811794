.maindiv {
    padding: 10px;
    padding-left: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 70px;
}

.headingfont {
    font-size: 34px;
    font-weight: 700;
    color: #08132D;
    width: 75%;
}

.content {
    font-size: 18px;
    font-weight: 400;
    color: #4C5877;
}

@media only screen and (max-width: 1130px) {
    .maindiv {
        padding: 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .headingfont {
        width: 100%;
    }
}

@media only screen and (max-width: 990px) {
    .headingfont {
        font-size: 30px;
    }
}
@media only screen and (max-width: 768px) {
    .headingfont {
        font-size: 20px;
        margin-bottom: 0;
    }
}
@media only screen and (max-width: 375px) {
  .content{
    font-size: 14px;
  }
 }