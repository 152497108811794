.maindiv {
    height: 80vh;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 30px;
    background-color: #FAFAFA;
    position: relative;
}

.learnmorebtn {
    color: #265EE1;
    border: 1px solid #265EE1;
    height: 8%;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.cardgroup {
    padding-bottom: 40px;
}

.processdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
}

.carousel {
    width: 2%;
}

.headingfont {
    color: #08132D;
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 0 !important;
    text-align: center;
}

.card {
    box-shadow: 0px 5.791126251220703px 57.91126251220703px 0px #D8D8D840;
    background-color: #FFFFFF;
    border: none;
    height: 100%;
    padding: 0;
}

.title {
    height: 10%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.contentwidth {
    width: 40%;
}

.content {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 8px;
    color: #4C5877;
}

.cardimg {
    height: 50%;
}

.cardtext {
    height: 22%;
    overflow: hidden;
    color: #4C5877;
    font-size: 20px;
    font-weight: 400;
}

.caroselwidth {
    height: 80%;
}

@media only screen and (max-width: 1440px) {
    .cardtext {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1300px) {
    .contentwidth {
        width: 50%;
    }
}

@media only screen and (max-width: 1200px) {
    .card {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}

@media only screen and (max-width: 1000px) {
    .maindiv {
        padding-left: 20px;
        padding-right: 20px;

    }

    .contentwidth {
        width: 60%;
    }
}


@media only screen and (max-width: 990px) {
    .headingfont {
        font-size: 25px;
    }

    .card {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    .cardtext {
        font-size: 13px;
    }

    .content {
        font-size: 14px;
    }

}

@media only screen and (max-width: 576px) {
    .cardgroup {
        display: flex;
        flex-flow: row wrap
    }

    .contentwidth {
        width: 100%;
    }

    .card {
        flex: 1 0 0%;
        margin: 0 !important;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        height: 85%;
    }
}

@media only screen and (max-width: 375px) {
    .card {
        padding: 0.5rem !important;
    }
}