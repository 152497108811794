.footer {
    background: #FFF;
    box-shadow: 0px 4px 24px 0px #C0C0C01F;
    padding-left: 60px;
    padding-right: 60px;
    height: 15vh;
    width: 100%;
}

.logoimg {
    height: 42px;
    width: 189px;
}

.content {
    font-size: 16px;
    font-weight: 400;
    color: #5F6D7E;
    margin-bottom: 0;
    margin-top: 16px;
}

.footermenubtn {
    color: #08132D;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.footermenu {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
}

@media only screen and (max-width: 990px) {
    .footer {
        padding-left: 20px;
        padding-right: 20px;
        height: 30vh;
    }
}

@media only screen and (max-width: 768px) {
    .footermenubtn {
        padding: 8px;
    }

}

@media only screen and (max-width: 600px) {
    .footer {
        height: 40vh;
    }

    .footermenu {
        display: inline;
    }

    .footermenubtn {
        padding: 3px;
    }
}

@media only screen and (max-width: 375px) {
    .footermenubtn {
        font-size: 12px;
    }

    .content {
        font-size: 14px;
    }

    .logoimg {
        height: 40px;
        width: 140px;
    }
}